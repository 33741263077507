import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const projects = [
  {
    name: "Beshaus Architecture Studio",
    href: "https://beshaus.com",
  },
  {
    name: "MoD Utilities",
    href: "https://darminer.com",
  },
  {
    name: "HoningJS Blog",
    href: "https://honingjs.com",
  },
  {
    name: `Forist Digital Agency`,
    href: "https://forist.intelake.com",
  },
  {
    name: `Yara Smallholder Digital Solution`,
    href: "https://yaradigital.com",
  },
  {
    name: "Poems Collection",
    href: "https://thobuon.org",
  },
]

const ProjectsPage = () => {
  return (
    <Layout title="Projects">
      <p className="text-3xl font-semibold">Recent projects</p>
      <div className="space-y-2 mt-10">
        {projects.map(({ name, href }, index) => (
          <div key={`project-${index}`}>
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className="text-blue-300 font-medium"
            >
              {name}
            </a>
          </div>
        ))}
        {/* <p className="font-medium">
          <span>(for more information, visit</span>{" "}
          <Link to={`/resume`} className="text-blue-300">
            Resume
          </Link>
          )
        </p> */}
      </div>
    </Layout>
  )
}

export default ProjectsPage
